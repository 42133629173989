import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import VueBodyClass from 'vue-body-class'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { bodyClass: 'background_home' },
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/About.vue')
  },
  {
    path: '/gallery',
    name: 'Gallery',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Gallery.vue')
  },
  {
    path: '/gallery/:year/:season',
    name: 'GallarySeason',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/GallarySeason.vue')
  },
  {
    path: '/gallery/:year/:season/:competition',
    name: 'GallaryPhotos',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/GallaryPhotos.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:id',
    name: 'Newsid',
    meta: { bodyClass: 'background_page' },
    props: true,
    component: () => import('../views/Newsid.vue')
  },
  {
    path: '/team',
    name: 'Team',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Team.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/rates',
    name: 'Rates',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Rates.vue')
  },
  {
    path: '/form',
    name: 'Form',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Form.vue')
  },
  {
    path: '/result',
    name: 'Result',
    meta: { bodyClass: 'background_page' },
    component: () => import('../views/Result.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });

export default router
