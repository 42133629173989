import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../src/assets/css/styles.scss';
import '../src/assets/css/styles-mobile.scss';
// import '@vueform/vueform/dist/vueform.css';

// import loader from "vue-ui-preloader";
// import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
// import VueFormulate from '@braid/vue-formulate'

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
// Vue.use(VueViewer);

// Vue.use(loader);
// Vue.use(BootstrapVue)
// Vue.use(VueFormulate)

// import { Hooper, Slide } from 'hooper-vue3';
// import 'hooper-vue3/dist/hooper.css';

// import { plugin as vueformPlugin } from '@vueform/vueform' // Исправлено

// import vueformConfig from '../src/vueform.config'

import { plugin, defaultConfig } from '@formkit/vue'


import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

createApp(App)
    .use(router)
    .use(plugin, defaultConfig)
    .component('Carousel', Carousel)
    .component('Slide', Slide)
    .component('Pagination', Pagination)
    .component('Navigation', Navigation)
    .mount('#app')
