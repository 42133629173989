<template>
    <div class="container">
        <div class="gradient"></div>
        <video
               preload="auto"
               playsinline=""
               data-autoplay-desktop="true"
               data-autoplay-portrait="true"
               data-autoplay-mobile="true"
               data-play-on-hover="false"
               muted=""
               loop=""
               poster=""
               data-poster-desktop=""
               data-poster-portrait=""
               data-poster-mobile=""
               data-src-desktop="/images/sait.mp4"
               data-src-portrait="/images/sait.mp4"
               data-src-mobile="/images/sait.mp4"
               data-src="/images/sait.mp4"
               data-object-fit="true"
               src="/images/sait.mp4"
               data-loaded="true"
               autoplay=""
        ></video>

        <div class="home">
            <h1 class="text-center">Команда профессионалов <br>«PRO ATHLETES FACTORY»</h1>
            <router-link to="/form" >
                <div class="btn_theme mt-4">Хочу в команду</div>
            </router-link>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'App',
        data: () => ({})
    }
</script>
<style>

</style>